import React from "react";

import { Button } from "../Button/Button.styles";

import {
  ContactContainer,
  ContactWrapper,
  ContactHeading,
  ContactPara,
  Squares,
} from "./Contact.styles";

const Contact = () => {
  return (
    <ContactContainer>
      <ContactWrapper id="contact">
        <div>
          <ContactHeading>Get in Touch</ContactHeading>
          <ContactPara>
            If you want to get in touch or learn more about what I do, don't
            hesitate to send me an email.
          </ContactPara>
          <Button href="mailto:rowanconnaughton@gmail.com" target="_blank">
            Get in Touch
          </Button>
        </div>
      </ContactWrapper>
      <Squares src={require("../../assets/squares.webp")} />
    </ContactContainer>
  );
};

export default Contact;
