import React from "react";

import { AiFillEye, AiFillGithub } from "react-icons/ai";

import {
  Container,
  PortfolioContainer,
  PortfolioHeadingContainer,
  PortfolioHeading,
  PortfolioWrapper,
  PortfolioItem,
  PortfolioImageWrapper,
  PortfolioImage,
  PortfolioHover,
  PortfolioContent,
  PortfolioTitle,
  PortfolioPara,
  PortfolioTagsWrapper,
} from "./Portfolio.styles";

import works from "./Data";

const Portfolio = () => {
  return (
    <Container id="projects">
      <PortfolioHeadingContainer>
        <PortfolioHeading>Projects</PortfolioHeading>
      </PortfolioHeadingContainer>
      <PortfolioContainer>
        <PortfolioWrapper>
          {works.map((work, index) => (
            <PortfolioItem key={index}>
              <PortfolioImageWrapper>
                <PortfolioImage src={work.img} />
                <PortfolioHover>
                  {work.weblink ? (
                    <a href={work.weblink} rel="noreferrer" target="_blank">
                      <div>
                        <AiFillEye />
                      </div>
                    </a>
                  ) : (
                    ""
                  )}
                  {work.gitlink ? (
                    <a href={work.gitlink} rel="noreferrer" target="_blank">
                      <div>
                        <AiFillGithub />
                      </div>
                    </a>
                  ) : (
                    ""
                  )}
                </PortfolioHover>
              </PortfolioImageWrapper>
              <PortfolioContent>
                <PortfolioTitle>{work.title}</PortfolioTitle>
                <PortfolioPara>{work.description}</PortfolioPara>
                <PortfolioTagsWrapper>
                  <PortfolioPara>{work.tags}</PortfolioPara>
                </PortfolioTagsWrapper>
              </PortfolioContent>
            </PortfolioItem>
          ))}
        </PortfolioWrapper>
      </PortfolioContainer>
    </Container>
  );
};

export default Portfolio;
