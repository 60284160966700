import React from 'react';

import { Button } from '../Button/Button.styles';

import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  SocialIcons,
  SocialIconLink,
} from './Sidebar.styles';

import { FaGithub, FaLinkedin, FaEnvelope } from 'react-icons/fa';

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink
            to="about"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            onClick={toggle}
          >
            About
          </SidebarLink>
          <SidebarLink
            to="projects"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            onClick={toggle}
          >
            Projects
          </SidebarLink>
          <SidebarLink
            to="skills"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            onClick={toggle}
          >
            Skills
          </SidebarLink>
          <SidebarLink
            to="contact"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            onClick={toggle}
          >
            Contact
          </SidebarLink>
        </SidebarMenu>

        <SideBtnWrap>
          <SocialIcons>
            <SocialIconLink
              href="https://github.com/RowanConnaughton"
              target="_blank"
              aria-label="Github"
            >
              <FaGithub />
            </SocialIconLink>

            <SocialIconLink
              href="https://www.linkedin.com/in/rowan-connaughton-b6237211a/"
              target="_blank"
              aria-label="Linkedin"
            >
              <FaLinkedin />
            </SocialIconLink>

            <SocialIconLink
              href="mailto:rowanconnaughton@gmail.com"
              target="_blank"
              aria-label="Email"
            >
              <FaEnvelope />
            </SocialIconLink>
          </SocialIcons>
        </SideBtnWrap>

        <SideBtnWrap>
          <Button
            href="https://www.rowanconnaughton.com/RowanConnaughtonCV.pdf"
            target="_blank"
          >
            Resume
          </Button>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
