import React from 'react';

import { Button } from '../Button/Button.styles';

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
} from './About.styles';

const About = () => {
  return (
    <>
      <InfoContainer id="about">
        <InfoWrapper>
          <InfoRow>
            <Column1>
              <TextWrapper>
                <TopLine>About Me</TopLine>
                <Subtitle>
                  I am an experienced Web Developer specializing in front-end
                  development, with expertise in a range of programming
                  languages and frameworks, including JavaScript, React, HTML,
                  CSS, ThreeJs, Sass, Node.js, SQL, WordPress, and other
                  essential web technologies.
                </Subtitle>

                <Subtitle>
                  With a solid educational background in Web Development and
                  Creative Media from Atlantic Technological University. I have
                  acquired a strong foundation in creating engaging and
                  user-friendly websites.
                </Subtitle>
                <BtnWrap>
                  <Button
                    href="https://www.rowanconnaughton.com/RowanConnaughtonCV.pdf"
                    target="_blank"
                  >
                    Resume
                  </Button>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={require('../../assets/logosquare.webp')} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default About;
