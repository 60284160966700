import React from 'react';

import { Button } from '../Button/Button.styles';

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  Squares1,
  Squares2,
} from './HomeSection.styles';

const HomeSection = () => {
  return (
    <>
      <InfoContainer id="home">
        <InfoWrapper>
          <InfoRow>
            <Column1>
              <TextWrapper>
                <TopLine>Hi, my name is </TopLine>
                <Heading>Rowan Connaughton</Heading>

                <Subtitle>
                  I'm a front-end developer with a passion for building web
                  applications. With my understanding of web development best
                  practices and emerging technologies, I am well-equipped to
                  deliver exceptional results on every project I undertake.
                </Subtitle>
                <BtnWrap>
                  <Button
                    href="https://www.rowanconnaughton.com/RowanConnaughtonCV.pdf"
                    target="_blank"
                  >
                    Resume
                  </Button>
                  <Button
                    href="https://www.rowanconnaughton.com/RowanConnaughtonCVPrint.pdf"
                    target="_blank"
                  >
                    Printer-friendly Resume
                  </Button>
                </BtnWrap>
              </TextWrapper>
            </Column1>
          </InfoRow>
        </InfoWrapper>
        <Squares1 src={require('../../assets/squares.webp')} />
        <Squares2 src={require('../../assets/squares.webp')} />
      </InfoContainer>
    </>
  );
};

export default HomeSection;
