export const skills = [
  {
    id: 1,
    name: 'React',
    img: require('../../assets/react.webp'),
  },

  {
    id: 2,
    name: 'JavaScript',
    img: require('../../assets/javascript.webp'),
  },
  {
    id: 3,
    name: 'HTML',
    img: require('../../assets/html.webp'),
  },
  {
    id: 4,
    name: 'CSS',
    img: require('../../assets/css.webp'),
  },
  {
    id: 5,
    name: 'Sass',
    img: require('../../assets/sass.webp'),
  },
  {
    id: 6,
    name: 'Node.js',
    img: require('../../assets/node.webp'),
  },
];

export const exps = [
  {
    id: 1,
    year: '2021-2023',
    title: 'Freelance Web Developer',
    tooltip:
      'Development of WordPress websites for clients. Hosting and email management. Assisting with marketing Google Ads and Analytics',
  },

  {
    id: 2,
    year: '2019-2021',
    title: 'Web Developer',
    subtitle: 'Maverick Vision',
    tooltip: 'Design websites for clients. Performed maintenance and updates',
  },

  {
    id: 3,
    year: '2016-2018',
    title: 'Undergraduate Student',
    subtitle: 'Atlantic Technological University',
    tooltip: 'Web Development and Creative Media',
  },
];
