import React from "react";

import { FaGithub, FaLinkedin, FaEnvelope } from "react-icons/fa";

import { ReactComponent as ReactLogo } from "../../assets/logo.svg";

import {
  FooterContainer,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./Footer.styles";

const Footer = () => {
  return (
    <FooterContainer>
      <SocialMediaWrap>
        <SocialLogo
          to="home"
          smooth={true}
          duration={500}
          spy={true}
          exact="true"
          offset={-80}
        >
          <ReactLogo />
        </SocialLogo>
        <WebsiteRights>
          Copyright © Rowan Connaughton {new Date().getFullYear()} All rights
          reserved
        </WebsiteRights>

        <SocialIcons>
          <SocialIconLink
            href="https://github.com/RowanConnaughton"
            target="_blank"
            aria-label="Github"
          >
            <FaGithub />
          </SocialIconLink>
          <SocialIconLink
            href="https://www.linkedin.com/in/rowan-connaughton-b6237211a/"
            target="_blank"
            aria-label="Linkedin"
          >
            <FaLinkedin />
          </SocialIconLink>
          <SocialIconLink
            href="mailto:rowanconnaughton@gmail.com"
            target="_blank"
            aria-label="Email"
          >
            <FaEnvelope />
          </SocialIconLink>
        </SocialIcons>
      </SocialMediaWrap>
    </FooterContainer>
  );
};

export default Footer;
