import styled from 'styled-components';

export const Button = styled.a`
  white-space: nowrap;
  padding: 10px 22px;
  color: #29f3c3;
  font-size: 16px;
  outline: none;
  border: 2px solid #29f3c3;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-right: 5px;
  cursor: pointer;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: rgba(41, 243, 195, 0.1);
    color: #29f3c3;
  }
`;
