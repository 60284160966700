import React, { useState } from "react";

import HomeSection from "../../components/HomeSection/HomeSection";

import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Nav/Navbar";
import About from "../../components/About/About";
import Portfolio from "../../components/Portfolio/Portfolio";
import SkillsSection from "../../components/SkillsSection/SkillsSection";
import FixedSocial from "../../components/FixedSocial/FixedSocial";

import Contact from "../../components/Contact/Contact";

import Footer from "../../components/Footer/Footer";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Navbar toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <HomeSection />
      <About />
      <Portfolio />
      <SkillsSection />
      <Contact />
      <Footer />
      <FixedSocial />
    </>
  );
};

export default Home;
