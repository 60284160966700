import styled from "styled-components";

export const ContactContainer = styled.section`
  position: relative;
  overflow: hidden;
`;

export const ContactWrapper = styled.section`
  display: flex;
  z-index: 1;
  height: 100vh;
  max-width: 1100px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ContactHeading = styled.h1`
  color: #29f3c3;
  font-size: 60px;
  font-weight: 700;

  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const ContactPara = styled.p`
  font-size: 20px;
  color: #fff;
  margin-bottom: 40px;
  max-width: 700px;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

export const Squares = styled.img`
  width: 500px;
  position: absolute;
  bottom: -200px;
  right: 80px;
  z-index: -999;
  transform: rotate(60deg);

  @media screen and (max-width: 1924px) {
    bottom: -300px;
    width: 500px;
  }

  @media screen and (max-width: 1320px) {
    bottom: -300px;
    width: 500px;
  }

  @media screen and (max-width: 992px) {
    bottom: -300px;
    width: 500px;
  }

  @media screen and (max-width: 768px) {
    bottom: -200px;
    width: 400px;
    right: 100px;
  }

  @media screen and (max-width: 576px) {
    display: none;
  }
`;
