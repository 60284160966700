import styled from "styled-components";

export const Container = styled.section``;

export const PortfolioContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`;

export const PortfolioHeadingContainer = styled.div`
  display: flex;

  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`;

export const PortfolioHeading = styled.h1`
  color: #fff;
  font-size: 60px;
  font-weight: 700;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const PortfolioWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const PortfolioItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 270px;
  max-width: 270px;
  max-height: 480px;
  flex-direction: column;
  margin: 2rem;
  padding: 1rem;

  border: 2px solid #29f3c3;
  background-color: #1f1f1f;
  color: #000;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 0 25px rgba(41, 243, 195, 0.4);
    transition: all 0.3s ease-in-out;
  }

  @media screen and (max-width: 480px) {
    margin: 1rem;
    padding: 0rem;
  }

  @media screen and (max-width: 300px) {
    width: 100%;
    margin: 1rem;
  }
`;

export const PortfolioImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  position: relative;

  display: no;
  @media screen and (max-width: 480px) {
    height: 150px;
  }
`;

export const PortfolioImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  object-fit: cover;
`;

export const PortfolioHover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(41, 243, 195, 0.5);
  border-radius: 0.5rem;
  opacity: 0;
  transition: all 0.3s ease;

  &:hover {
    opacity: 1;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(31, 31, 31, 0.5);
    color: #fff;
    margin: 1rem;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;

    svg {
      width: 50%;
      height: 50%;
      color: #fff;
    }
  }
`;

export const PortfolioContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
  position: relative;
  flex-direction: column;
`;

export const PortfolioTitle = styled.h2`
  align-self: flex-start;
  margin-top: 1rem;
  line-height: 1.5;
  font-size: 18px;
  color: #fff;

  @media screen and (min-width: 2000px) {
    margin-top: 3rem;
  }
`;

export const PortfolioPara = styled.p`
  color: #fff;
  font-size: 12px;
`;

export const PortfolioTagsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 0.5rem 0.5rem;
  border-radius: 10px;
  border: 2px solid #29f3c3;
  background-color: #1f1f1f;
  top: -25px;
  p {
    color: #fff;
  }
`;
