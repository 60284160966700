import styled from "styled-components";

export const InfoContainer = styled.section`
  color: #fff;
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;

  height: 860px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`;

export const InfoRow = styled.div`
  display: grid;
  flex-direction: row;
  align-items: center;
  grid-template-areas: "col1 col2";

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-self: baseline;
    justify-content: center;
  } ;
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const TextWrapper = styled.div`
  max-width: 600px;
  padding-top: 0;
  padding-bottom: 60px;
`;

export const TopLine = styled.h1`
  color: #29f3c3;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1.4px;

  &:after {
    content: "";
    display: block;
    position: relative;
    top: -5px;
    width: 300px;
    height: 1px;
    margin-left: 100px;
    background-color: #29f3c3;
  }

  @media screen and (max-width: 768px) {
    &:after {
      content: "";
      display: block;
      position: relative;
      top: -5px;
      width: 200px;
      height: 1px;
      margin-left: 100px;
      background-color: #29f3c3;
    }
  }

  @media screen and (max-width: 480px) {
    &:after {
      content: "";
      display: block;
      position: relative;
      top: -5px;
      width: 100px;
      height: 1px;
      margin-left: 100px;
      background-color: #29f3c3;
    }
  }
`;

export const Heading = styled.h1`
  font-size: 80px;
  font-weight: 700;
  margin: 20px 0px;
  color: #f7f8fa;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 600px;
  font-size: 20px;
  color: #fff;
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ImgWrap = styled.div`
  max-width: 250px;
  height: 100%;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;
