import styled from "styled-components";

export const SocialContainer = styled.div`
  width: 50px;
  position: fixed;
  bottom: 0px;
  left: 50px;
  right: auto;
  z-index: 10;
  color: #fff;

  @media screen and (max-width: 1100px) {
    display: none;
  }
`;

export const SocialList = styled.ul`
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
  list-style: none;

  &:after {
    content: "";
    display: block;
    width: 1px;
    height: 100px;
    margin: 0px auto;
    background-color: #29f3c3;
  }
`;

export const SocialItem = styled.li`
  font-size: 40px;
`;

export const SocialItemLink = styled.a`
  text-decoration: none;
  color: #fff;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;

    color: #29f3c3;

    svg {
      transform: scale(1.2);
    }
  }
`;
