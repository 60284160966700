import React, { useState } from "react";
import { skills, exps } from "./Data";

import {
  Section,
  SkillsContainer,
  SkillsList,
  SkillsItem,
  SkillsItemImgWrapper,
  SkillItemImg,
  SkillItemText,
  SkillsExpContainer,
  SkillsExpItem,
  SkillsExpYearWrapper,
  SkillsExpYear,
  SkillsExpWrapper,
  SkillsExpContent,
  SkillsExpTitle,
  SkillsExpPara,
  SkillsExpToolTip,
  SectionHeading,
} from "./SkillsSection.styles";

const SkillsSection = () => {
  const [tooltip, showTooltip] = useState(true);

  return (
    <Section id="skills">
      <div>
        <SectionHeading>
          <span>Skills</span> & <span>Experiences</span>
        </SectionHeading>
        <SkillsContainer>
          <SkillsList>
            {skills.map((skill, index) => (
              <SkillsItem key={index}>
                <SkillsItemImgWrapper>
                  <SkillItemImg src={skill.img} alt={skill.name} />
                </SkillsItemImgWrapper>
                <SkillItemText>{skill.name}</SkillItemText>
              </SkillsItem>
            ))}
          </SkillsList>

          <SkillsExpContainer>
            {exps.map((exp, index) => (
              <SkillsExpItem key={index}>
                <SkillsExpYearWrapper>
                  <SkillsExpYear>{exp.year}</SkillsExpYear>
                </SkillsExpYearWrapper>
                <SkillsExpWrapper>
                  <SkillsExpContent
                    data-tip={exp.tooltip}
                    data-for={exp.name}
                    onMouseEnter={() => showTooltip(true)}
                    onMouseLeave={() => {
                      showTooltip(false);
                      setTimeout(() => showTooltip(true), 50);
                    }}
                  >
                    <SkillsExpTitle>{exp.title}</SkillsExpTitle>
                    {exp.subtitle ? (
                      <SkillsExpPara>{exp.subtitle}</SkillsExpPara>
                    ) : (
                      ""
                    )}
                  </SkillsExpContent>
                  {tooltip && (
                    <SkillsExpToolTip
                      id={exp.name}
                      data-place="top"
                      effect="solid"
                    />
                  )}
                </SkillsExpWrapper>
              </SkillsExpItem>
            ))}
          </SkillsExpContainer>
        </SkillsContainer>
      </div>
    </Section>
  );
};

export default SkillsSection;
