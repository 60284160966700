import styled from "styled-components";

export const InfoContainer = styled.section`
  color: #fff;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  z-index: 1;
  height: 100vh;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`;

export const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  } ;
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const TextWrapper = styled.div`
  //max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
`;

export const TopLine = styled.p`
  color: #29f3c3;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1.4px;
`;

export const Heading = styled.h1`
  font-size: 80px;
  font-weight: 700;
  margin: 20px 0px;
  color: #f7f8fa;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 550px;
  font-size: 20px;
  color: #fff;
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ImgWrap = styled.div`
  position: fixed;
  right: 100px;
  top: 100px;
  /* max-width: 619px; */
  width: 705px;
  height: 100%;
  z-index: -99;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;

export const Squares1 = styled.img`
  width: 500px;
  position: absolute;
  top: -200px;
  left: 0px;
  z-index: -999;
  transform: rotate(50deg);

  @media screen and (max-width: 1850px) {
    top: 0px;
    width: 300px;
  }

  @media screen and (max-width: 1440px) {
    top: 0px;
    width: 200px;
  }

  @media screen and (max-width: 1110px) {
    top: 0px;
    width: 100px;
  }

  @media screen and (max-width: 768px) {
    top: 0px;
    width: 200px;
  }
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

export const Squares2 = styled.img`
  width: 500px;
  position: absolute;
  top: 0px;
  right: 20px;
  z-index: -999;
  transform: rotate(60deg);

  @media screen and (max-width: 1924px) {
    top: 80px;
    width: 500px;
  }

  @media screen and (max-width: 1320px) {
    top: 300px;
    width: 300px;
  }

  @media screen and (max-width: 992px) {
    top: 380px;
    width: 200px;
  }

  @media screen and (max-width: 768px) {
    top: 600px;
    width: 200px;
  }

  @media screen and (max-width: 576px) {
    display: none;
  }
`;
