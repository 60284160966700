import React from "react";

import {
  SocialContainer,
  SocialList,
  SocialItemLink,
  SocialItem,
} from "./FixedSocial.styles";

import { FaGithub, FaLinkedin, FaEnvelope } from "react-icons/fa";

const FixedSocial = () => {
  return (
    <SocialContainer>
      <SocialList>
        <SocialItem>
          <SocialItemLink
            href="https://github.com/RowanConnaughton"
            target="_blank"
            aria-label="Github"
          >
            <FaGithub />
          </SocialItemLink>
        </SocialItem>
        <SocialItem>
          <SocialItemLink
            href="https://www.linkedin.com/in/rowan-connaughton-b6237211a/"
            target="_blank"
            aria-label="Linkedin"
          >
            <FaLinkedin />
          </SocialItemLink>
        </SocialItem>

        <SocialItem>
          <SocialItemLink
            href="mailto:rowanconnaughton@gmail.com"
            target="_blank"
            aria-label="Email"
          >
            <FaEnvelope />
          </SocialItemLink>
        </SocialItem>
      </SocialList>
    </SocialContainer>
  );
};

export default FixedSocial;
