import styled from "styled-components";

import ReactTooltip from "react-tooltip";

export const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 100vh;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;

  @media screen and (max-width: 768px) {
    margin-top: 100px;
  }
`;

export const SectionHeading = styled.h1`
  text-align: center;
  color: #fff;
  font-size: 60px;
  font-weight: 700;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }

  span {
    color: #29f3c3;
  }
`;

export const SkillsContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }

  @media screen and (max-width: 450px) {
    margin-top: 1rem;
  }
`;

export const SkillsList = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  margin-right: 5rem;

  @media screen and (max-width: 900px) {
    margin-right: 0;
    justify-content: center;
    align-items: center;
  }
`;

export const SkillsItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  text-align: center;

  margin: 1rem;

  transition: all 0.3s ease-in-out;

  @media screen and (min-width: 2000px) {
    width: 150px;
    height: 150px;
  }

  @media screen and (max-width: 450px) {
    width: 70px;
  }
`;

export const SkillsItemImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #fef4f5;

  &:hover {
    box-shadow: 0 0 25px #fef4f5;
  }
`;

export const SkillItemImg = styled.img`
  width: 50%;
  height: 50%;
`;

export const SkillItemText = styled.p`
  font-weight: 500;
  margin-top: 0.5rem;
  color: #29f3c3;
`;

export const SkillsExpContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    margin-top: 2rem;
  }
  @media screen and (max-width: 450px) {
    margin-left: 1.5rem;
  }
`;

export const SkillsExpItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 1rem 0;
`;

export const SkillsExpYearWrapper = styled.div`
  margin-right: 3rem;

  @media screen and (max-width: 450px) {
    margin-right: 1rem;
  }
`;

export const SkillsExpYear = styled.p`
  font-weight: 800;
  color: #29f3c3;
`;

export const SkillsExpWrapper = styled.div`
  flex: 1;
`;

export const SkillsExpContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1rem;
  cursor: pointer;
`;

export const SkillsExpTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  color: #29f3c3;
`;

export const SkillsExpPara = styled.p`
  font-weight: 400;
  color: #fff;
  margin-top: 5px;
`;

export const SkillsExpToolTip = styled(ReactTooltip)`
  max-width: 300px !important;
  /* background-color: #fff !important;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
  padding: 1rem !important;
  color: #000 !important;
  text-align: center !important;
  line-height: 1.5 !important;
  opacity: 1 !important;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem !important;
    max-width: 500px !important;
    line-height: 2 !important;
  } */
`;
