const works = [
  {
    id: 1,
    title: 'Hospitality Jobs Ireland',
    description:
      'Is a multi-language recruitment site. The site aims to increase recruitment in targeted areas. Developed with WordPress and WPML ',
    img: require('../../assets/hospitalityjobsireland.webp'),
    weblink: 'https://hospitalityjobsireland.ie/',
    tags: 'WordPress',
  },

  {
    id: 2,
    title: 'React Ecommerce App',
    description:
      'The React app uses firebase for user logins and storing cart items. Stripe for payments, Redux for state management and styled components for css in js',

    gitlink: 'https://github.com/RowanConnaughton/Eccomerce-React-app',
    img: require('../../assets/ecommerce.webp'),
    tags: 'React',
  },

  {
    id: 3,
    title: 'Rocket League - React Three Fiber',
    description:
      "This app use's react three fiber, gltfjsx and Sass. I Have plans to implement a simple playable version",

    gitlink:
      'https://github.com/RowanConnaughton/Rocket-league-Menu-Clone-React-Three-Fiber',
    img: require('../../assets/threejs.webp'),
    tags: 'ThreeJs',
  },

  {
    id: 4,
    title: 'Javascript Side Scroller',
    description:
      '2D Side Scroller. Built with html, canvas, css and vanilla javascript no frameworks',
    weblink: 'https://rowanconnaughton.github.io/2D-Side-Scroller',
    gitlink: 'https://github.com/RowanConnaughton/2D-Side-Scroller',
    img: require('../../assets/sidescroller.webp'),
    tags: 'JavaScript',
  },

  {
    id: 5,
    title: 'Ireland Accommodation',
    description:
      'Ireland Accommodation is a Relocation Support network. Developed during my time with Maverick Vision.',

    img: require('../../assets/irelandaccommodation.webp'),
    tags: 'WordPress',
  },

  {
    id: 6,
    title: 'Habitare Servizi Immobiliari',
    description:
      'I developed this wordpress site for a client during my time at Maverick Vision.',
    weblink: 'https://www.habitareservizi.it/',
    img: require('../../assets/habitare.webp'),
    tags: 'WordPress',
  },
];

export default works;
